<template>
  <b-overlay :show="isLoading" rounded="sm">
    <b-card>
      <h1 class="mb-3">Add a new language</h1>
      <b-row>
        <b-col cols="6">
          <b-form @submit.prevent>
            <b-form-group label="English Name For Language" label-for="name">
              <b-form-input
                id="name"
                trim
                placeholder="English"
                v-model="languageDetails.name"
              />
            </b-form-group>
          </b-form>
        </b-col>
        <b-col cols="6">
          <b-form @submit.prevent>
            <b-form-group
              label="Persian Name For Language"
              label-for="persianName"
            >
              <b-form-input
                id="persianName"
                trim
                placeholder="انگلیسی"
                v-model="languageDetails.persianName"
              />
            </b-form-group>
          </b-form>
        </b-col>
        <b-col cols="12">
          <div class="w-100 d-flex align-items-center justify-content-end">
            <b-button variant="primary" @click="createNewLanguage">
              Create New Language
            </b-button>
          </div>
        </b-col>
      </b-row>
    </b-card>
  </b-overlay>
</template>

<script>
export default {
  data() {
    return {
      isLoading: false,
      languageDetails: {
        languageId: 0,
        name: null,
        persianName: null,
        createDate: new Date(Date.now()).toISOString(),
      },
    };
  },
  methods: {
    async createNewLanguage() {
      try {
        this.isLoading = true;
        let _this = this;
        let createNewLang = new CreateNewLang(_this);
        createNewLang.setRequestParamDataObj(_this.languageDetails);
        await createNewLang.fetch((response) => {
          if (response.isSuccess) {
            _this.$toast({
              component: ToastificationContent,
              position: "bottom-center",
              props: {
                title: "Operation was successful",
                icon: "CheckIcon",
                variant: "success",
                text: "Language Created Successfully",
              },
            });
            _this.languageDetails = {
              languageId: 0,
              name: null,
              persianName: null,
              createDate: new Date(Date.now()).toISOString(),
            };
          }
        });
      } catch (error) {
        console.error(error);
      } finally {
        this.isLoading = false;
      }
    },
  },
  head() {
    return {
      title: "Create a new language",
    };
  },
  components: {
    BCard,
    BMedia,
    BAvatar,
    BCardText,
    BMediaAside,
    BMediaBody,
    BForm,
    BRow,
    BCol,
    BFormGroup,
    BFormInput,
    BImg,
    BFormFile,
    BLink,
    BButton,
    BFormTextarea,
    BOverlay,
  },
};
import ToastificationContent from "@core/components/toastification/ToastificationContent";
import { CreateNewLang } from "@/libs/Api/Language";
import {
  BCard,
  BMedia,
  BAvatar,
  BCardText,
  BMediaAside,
  BMediaBody,
  BForm,
  BRow,
  BCol,
  BFormGroup,
  BFormInput,
  BImg,
  BFormFile,
  BLink,
  BButton,
  BFormTextarea,
  BOverlay,
} from "bootstrap-vue";
</script>
